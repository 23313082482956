<template>
  <v-app>
    <div v-if="isLoading" class="white-overlay">
      <v-progress-linear
        color="primary"
        indeterminate
        height="5"
      ></v-progress-linear>
      <div class="loader-wrapper"></div>
    </div>
    <simple-view />
  </v-app>
</template>

<script>
export default {
  name: "SimpleLayout",

  components: {
    SimpleView: () =>
      import(
        /* webpackChunkName: "simple-view" */
        "./SimpleView"
      )
  },
  computed: {
    isLoading() {
      return (
        this.$store.getters["app/asyncProgress"].length > 0 ||
        this.$store.getters["app/myLoading"]
      );
    }
  }
};
</script>
